import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Router, Switch } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';

import './assets/styles/style.scss';
import history from './core/utils/history';
import theme from './theme';
import Home from './pages/Home';
import Team from './pages/Team';

ReactDOM.render(
  <React.StrictMode>
    <MuiThemeProvider theme={theme}>
      <Router history={history}>
        <Switch>
          <Route path="/team" exact component={Team} />
          <Route path="/" exact component={Home} />
        </Switch>
      </Router>
    </MuiThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
